import React from 'react';
import PropTypes from 'prop-types';

import './scss/about-section.scss';
import { CMSLink } from 'components/cmsLink';
import Arrow from 'icons/arrow-down.svg';
import { Image } from 'components/conditionalImage';

const AboutSection = ({
  title,
  image,
  imageText,
  link,
  linkText,
  description,
}) => (
  <section className="about-info">
    <div className="about-info__content-wrapper">
      <figure className="about-info__figure">
        <Image
          image={image}
          imageText={imageText}
          className="about-info__image"
        />
      </figure>
      <div className="about-info__content">
        {title && <h3 className="about-info__name">{title}</h3>}
        <p className="about-info__description">{description}</p>
        {link && linkText && (
          <CMSLink linkUrl={link} className="btn-link">
            {linkText}
            <Arrow />
          </CMSLink>
        )}
      </div>
    </div>
  </section>
);

AboutSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]),
  imageText: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkText: PropTypes.string,
};

export { AboutSection };
