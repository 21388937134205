import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import './scss/styles.scss';
import { HeroBannerImage } from 'components/heroBanner';
import { Layout } from 'layout';
import { AboutSection } from 'components/aboutSection';
import { DownloadForm } from './components';

const BookDownloadPage = ({ data }) => {
  const { markdownRemark } = data;

  return (
    <Layout
      darkHeader
      metaTitle={
        markdownRemark.frontmatter.metaInformation.metaTitle ||
        markdownRemark.frontmatter.bookPromoTitle
      }
      metaDescription={
        markdownRemark.frontmatter.metaInformation.metaDescription || null
      }
      metaKeywords={
        markdownRemark.frontmatter.metaInformation.metaKeywords || null
      }
      metaImage={
        markdownRemark.frontmatter.metaInformation.metaImage
          ? markdownRemark.frontmatter.metaInformation.metaImage.children[0]
              .fixed.src
          : null
      }
    >
      <HeroBannerImage
        backgroundImage={
          markdownRemark.frontmatter.bookPromoBackground &&
          markdownRemark.frontmatter.bookPromoBackground.children[0].fixed.src
        }
        title={markdownRemark.frontmatter.bookPromoTitle}
        description={markdownRemark.frontmatter.bookPromoDescription}
        heroImage={
          markdownRemark.frontmatter.bookPromoImage &&
          markdownRemark.frontmatter.bookPromoImage.children[0].fluid
        }
        heroImageText="The Secret Source Book"
        noGradient
      />
      <div className="book-download__content">
        <div className="book-download__form-wrapper">
          <DownloadForm
            formTitle={markdownRemark.frontmatter.form.title}
            formDescription={markdownRemark.frontmatter.form.description}
            downloadUrl={markdownRemark.frontmatter.form.downloadUrl}
          />
        </div>
        <div className="book-download__text-wrapper">
          <AboutSection
            description={markdownRemark.frontmatter.authorSection.description}
            image={
              markdownRemark.frontmatter.authorSection.image &&
              markdownRemark.frontmatter.authorSection.image.children[0].fluid
            }
            imageText={markdownRemark.frontmatter.authorSection.title}
            link={markdownRemark.frontmatter.authorSection.linkUrl}
            linkText={markdownRemark.frontmatter.authorSection.linkText}
            title={markdownRemark.frontmatter.authorSection.title}
          />
          <AboutSection
            description={markdownRemark.frontmatter.companySection.description}
            image={
              markdownRemark.frontmatter.companySection.image &&
              markdownRemark.frontmatter.companySection.image.children[0].fluid
            }
            imageText={markdownRemark.frontmatter.companySection.title}
            link={markdownRemark.frontmatter.companySection.linkUrl}
            linkText={markdownRemark.frontmatter.companySection.linkText}
            title={markdownRemark.frontmatter.companySection.title}
          />
        </div>
      </div>
    </Layout>
  );
};

BookDownloadPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape(),
  }),
};

export const bookDownloadPageQuery = graphql`
  query BookDownloadPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      id
      tableOfContents(maxDepth: 2)
      frontmatter {
        metaInformation {
          metaTitle
          metaDescription
          metaKeywords
          metaImage {
            children {
              ... on ImageSharp {
                fixed(width: 1080, height: 1080) {
                  src
                }
              }
            }
          }
        }
        authorSection {
          title
          description
          linkUrl
          linkText
          image {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        companySection {
          title
          description
          linkUrl
          linkText
          image {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        form {
          title
          description
          downloadUrl
        }
        bookPromoImage {
          children {
            ... on ImageSharp {
              fluid(maxWidth: 335, maxHeight: 387) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        bookPromoBackground {
          children {
            ... on ImageSharp {
              fixed(width: 1500) {
                src
              }
            }
          }
        }
        bookPromoDescription
        bookPromoTitle
        templateKey
      }
    }
  }
`;

export default BookDownloadPage;
