import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import classNames from 'classnames';

import { LoadingIndicator } from 'components/loadingIndicator';
import {
  FormField,
  SubmitButton,
  SubmitMessageWithIcon,
} from 'components/forms';
import 'components/forms/scss/form.scss';
import { isWhiteSpace } from 'utilities';

const requiredFieldText = 'Field is required.';
const minFieldText = 'Field value is too short.';
const maxFieldText = 'Field value is too long.';
const whiteSpaceFieldText = 'Field must contain letters or numbers.';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min('1', minFieldText)
    .max('100', maxFieldText)
    .required(requiredFieldText)
    .test(
      'check-whitespace',
      whiteSpaceFieldText,
      (value) => !isWhiteSpace(value)
    ),
  lastName: Yup.string()
    .min('1', minFieldText)
    .max('100', maxFieldText)
    .required(requiredFieldText)
    .test(
      'check-whitespace',
      whiteSpaceFieldText,
      (value) => !isWhiteSpace(value)
    ),
  email: Yup.string()
    .email('Field value must be a valid email.')
    .required(requiredFieldText),
  company: Yup.string()
    .min('1', minFieldText)
    .max('100', maxFieldText)
    .test(
      'check-whitespace',
      whiteSpaceFieldText,
      (value) => !isWhiteSpace(value)
    ),
});

const DownloadForm = ({ formTitle, formDescription, downloadUrl }) => {
  const [formIsSubmitted, setIsFormSubmitted] = useState(false);
  const [formBeingSubmitted, setFormBeingSubmitted] = useState(false);
  const [hasSubmissionError, setSubmissionError] = useState(false);

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join('&');
  };

  const handleSubmit = (values, actions) => {
    setFormBeingSubmitted(true);
    fetch('', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'book-download',
        ...values,
      }),
    })
      .then(() => {
        actions.setSubmitting(false);
        actions.setStatus('submitted');
        setIsFormSubmitted(true);
        setFormBeingSubmitted(false);

        if (downloadUrl && typeof window !== 'undefined') {
          window.open(downloadUrl, '_blank');
          window.dataLayer.push({ event: 'download' });
        }
      })
      .catch(() => {
        actions.setSubmitting(false);
        setSubmissionError(true);
        setFormBeingSubmitted(false);
      });
  };

  const handleFieldFocus = () => {
    if (hasSubmissionError) {
      setSubmissionError(false);
    }
  };

  return (
    <div
      className={classNames('form-tile', {
        submitted: formIsSubmitted || formBeingSubmitted,
      })}
    >
      <div className="form-tile__content" aria-hidden={formIsSubmitted}>
        <h2 className="form-tile__title">{formTitle}</h2>
        <p className="form-tile__description">{formDescription}</p>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            company: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form
              name="book-download"
              method="post"
              data-netlify="true"
              className={classNames(
                'form',
                { submitted: formIsSubmitted || formBeingSubmitted },
                { error: hasSubmissionError }
              )}
            >
              {hasSubmissionError && (
                <p className="form__error">Error submitting form</p>
              )}
              <FormField
                onFocus={handleFieldFocus}
                label="First Name"
                name="firstName"
              />
              <FormField
                onFocus={handleFieldFocus}
                label="Last Name"
                name="lastName"
              />
              <FormField
                onFocus={handleFieldFocus}
                label="Email"
                name="email"
                type="email"
              />
              <FormField
                onFocus={handleFieldFocus}
                label="Company"
                name="company"
              />
              <SubmitButton>Download</SubmitButton>
            </Form>
          )}
        </Formik>
      </div>
      {formBeingSubmitted && <LoadingIndicator />}
      {formIsSubmitted && (
        <>
          <SubmitMessageWithIcon
            title="Thank you!"
            description={`<p>Your download will start shortly.</p>`}
          />
        </>
      )}
    </div>
  );
};

DownloadForm.propTypes = {
  formTitle: PropTypes.string.isRequired,
  formDescription: PropTypes.string.isRequired,
  downloadUrl: PropTypes.string,
};

export { DownloadForm };
